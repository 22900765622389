import React, { useState, useEffect } from 'react'

import '../styles/AppStyles.js'
import classnames from 'classnames'

import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import { useAuth } from '../providers/AuthProvider'
import ToastProvider from '../providers/ToastProvider'

import ListItemButton from '@mui/material/ListItemButton'
import SvgIcon from '@mui/material/SvgIcon'
import LogoutIcon from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import { ReactComponent as HomeIcon } from '../assets/Home.svg'
import { ReactComponent as HamburgerIcon } from '../assets/Hamburger.svg'
import { ReactComponent as InsightsIcon } from '../assets/Insights.svg'
import { ReactComponent as SusIcon } from '../assets/Sus.svg'
import { ReactComponent as PersonIcon } from '../assets/Person.svg'
import { ReactComponent as MeasureIcon } from '../assets/Measure.svg'
import { ReactComponent as BarGraphArrowIcon } from '../assets/BarGraphArrow.svg'
import { ReactComponent as LocationsIcon } from '../assets/Locations.svg'


import ChLogo from '../assets/ChLogo'
import ChWordmark from '../assets/ChWordmark'


export default function AppFrame({ children }) {
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const defaultNav = [
    {
      text: "Home",
      id: "home",
      icon: HomeIcon,
      href: '/'
    },
    {
      text: "Measure",
      id: "measure",
      icon: MeasureIcon,
      href: '/questionnaire/start'
    },
    {
      text: "Carbon Footprint",
      id: "carbon-footprint",
      icon: InsightsIcon,
      href: '/insights/details',
      subheaders: [
        {
          text: "Locations",
          id: "locations",
          icon: LocationsIcon,
          href: '/locations'
        },
        {
          text: "Waste",
          id: "waste",
          icon: LocationsIcon,
          href: '/waste-management'
        },
      ],
    },
    {
      text: "Sustainability",
      id: "sustainability",
      icon: SusIcon,
      href: '/sustainability/edit'
    },
    {
      text: "Planning",
      id: "planning",
      icon: BarGraphArrowIcon,
      href: '/emissions-planning'
    },
    {
      text: "Profile",
      id: "profile",
      icon: PersonIcon,
      href: '/profile/edit'
    },
  ]

  const [ nav, setNav ] = useState(defaultNav)

  // set the flags that we will use throughout our nav
  // rendering and decision processes
  const userIsLoggedIn = !!auth.userInfo
  const hasReport = auth.userInfo?.report_available
  const reportHasWaste = auth.userInfo?.show_waste_report
  const currentYearInProgress = userIsLoggedIn && auth.userInfo.most_recent_qnn_year !== auth.userInfo.most_recent_calc_year

  // this returns true if we can click on it and go there
  // otherwise it will be disabled. Defaults to true so make
  // sure checks return false
  const isNavItemEnabled = (navItem) => {

    // list everything that is permanently enabled and navagable
    const alwaysEnabledNavIds = [ 'locations', 'home', 'profile' ]
    if (alwaysEnabledNavIds.indexOf(navItem.id) > -1) {
      return true
    }

    // all nav that needs to have a report
    const reportDependantNavIds = [ 'carbon-footprint', 'sustainability', 'planning', 'waste' ]
    if (reportDependantNavIds.indexOf(navItem.id) > -1) {
      if (!hasReport) {
        return false
      }
      if (navItem.id === 'waste') {
        if (!reportHasWaste) {
          return false
        }
      }
    }

    // disable measure tab if we aren't in a calculation
    if (navItem.id === "measure") {
      if (!currentYearInProgress) {
        return false
      }
    }

    // default to permissive nav
    return true
  }

  const isNavItemPermitted = (navItem) => {
    if (!userIsLoggedIn) return false

    const canMeasure = auth.userInfo.is_acct_owner || auth.userInfo.permissions?.measure
    const canFootprint = auth.userInfo.is_acct_owner || auth.userInfo.permissions?.carbon_footprint

    // allow measure if we can
    if (canMeasure && navItem.id === "measure") {
      return true
    }

    // allow footprint if we can
    if (canFootprint && navItem.id === "carbon-footprint") {
      return true
    }

    // default allow the nav to logged in users
    return true
  }

  const goToNavItem = (navItem) => {
    if (isNavItemEnabled(navItem)) {
      navigate(navItem.href)
    }
    setOpen(false)
  }

  return(
    <div>
      <ToastProvider>
        <div className="mobileHeader">
          <div className="hamburger" onClick={handleDrawerOpen}>
            <HamburgerIcon />
          </div>
          <div className="logo">
            <ChWordmark />
          </div>
        </div>

        <div className={classnames('navDrawerCloseTarget', {open: open, notOpen: !open})} onClick={handleDrawerClose}>
        </div>

        <div className={`navDrawer ${open ? 'open' : 'notOpen'}`}>
          <header onClick={() => (navigate('/'))}>
            <div className="drawerTarget closed">
              <ChLogo />
            </div>
            <div className="drawerTarget open">
              <ChWordmark />
            </div>
          </header>
          <ul>
            {userIsLoggedIn && nav.map((navItem, index) => {
              return (
                <li key={navItem.text} className={classnames({disabled: !isNavItemEnabled(navItem), notPermitted: !isNavItemPermitted(navItem)})}>
                  <ListItemButton onClick={() => {goToNavItem(navItem)}}>
                    <span className={navItem.href === window.location.pathname ? 'icon current' : 'icon'}>
                      {auth.userInfo?.supplier_review_status === "client" && navItem.text === "Measure" && <div className='noti'></div>}
                      <SvgIcon component={navItem.icon} inheritViewBox />
                    </span>
                    <span className="label">{navItem.text}</span>
                  </ListItemButton>
                    <ul className="subHeaderList">
                      {
                        navItem.subheaders?.map( (navItem) => (
                          <li key={navItem.text} className={classnames({disabled: !isNavItemEnabled(navItem), notPermitted: !isNavItemPermitted(navItem)})}>
                            <ListItemButton onClick={() => {goToNavItem(navItem)}}>
                              <span className={navItem.href === window.location.pathname ? 'icon current' : 'icon'}>
                                <div className="subNavBullet"></div>
                              </span>
                              <span className="label subNavLabel">{navItem.text}</span>
                            </ListItemButton>
                          </li>)
                        )
                      }
                    </ul>
                </li>
              )
            })}
          </ul>
          <div className="logOut">
            {(auth.userInfo?.role === "admin") && <ListItemButton onClick={() => {navigate('/admin');}}>
              <span className="icon">
               <AdminPanelSettingsIcon />
              </span>
              <span className="label">Admin</span>
            </ListItemButton>}
            <ListItemButton onClick={() => {auth.logOut(); navigate('/');}}>
              <span className="icon">
               <LogoutIcon />
              </span>
              <span className="label">Logout</span>
            </ListItemButton>
          </div>
        </div>

        <div id="main">
          <Outlet />
        </div>
      </ToastProvider>
    </div>
  )
}
