import React, {useState, useEffect, createRef} from 'react'
import LabeledSelect from '../Forms/LabeledSelect'
import Toggler from '../Forms/Toggler'
import { useLocation } from '../../providers/LocationProvider'
import axios from 'axios'
import classnames from 'classnames'
import 'chartjs-adapter-moment';
import EmissionSection from './EmissionSection'
import WasteDonut from '../Charts/WasteDonut'
import Tooltip from '../Tooltip'
import HomeStatBlock from './HomeStatBlock'

const WasteManagement = (props) => {
  const [wasteReport, setWasteReport] = useState(null)
  const [ currentSectionId, setCurrentSectionId ] = useState(null)
  const [ questionnaires, setQuestionnaires ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ displayType, setDisplayType ] = useState('total_short_tons')
  const params = new URLSearchParams(window.location.search)
  const [reportId, setReportId] = useState(params.get('report_id'))
  const userId = params.get('user_id')
  const [activeSlices, setActiveSlices] = useState([])
  const chartRef = createRef()

  let landfillGradient, diversionGradient = []

  useEffect(() => {
    axios.get('/api/questionnaires/' + (userId ? `?user_id=${userId}` : "")).then((res) => {
      const formattedData = res.data.filter(q => q.has_report)
      setQuestionnaires(formattedData)
    }).catch(
      err => {
        console.log(err)
      }
    )
  }, [])

  useEffect(() => {
    if(!questionnaires.length) {
      return
    }
    let url = `/api/reports/${reportId || "current"}/waste`
    setLoading(true)
    axios.get(url).then((res) => {
      setWasteReport(res.data)
      setCurrentSectionId(res.data.section_id)
    })
  }, [reportId, questionnaires])

  const handleAccordionClick = (id) => {
    (currentSectionId === id) ? setCurrentSectionId(null) : setCurrentSectionId(id)
  }

  const Header = () => (
    <header>
      <div className="main">
        <h2>Waste Management</h2>
        {(questionnaires.filter(qnn => qnn.show_waste_report).length > 1) && <LabeledSelect 
        name="reportId"
        options={questionnaires.filter(qnn => qnn.show_waste_report).map( qnn => ({label: qnn.year, value: qnn.report_id}))}
        values={{reportId}}
        onChange={(newVal) => setReportId(newVal.target.value)}
      />}
      </div>
    </header>
  )


    function interpolateColor(color1, color2, factor) {
    const hexToRgb = hex => {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return [r, g, b];
    };

    const rgbToHex = (r, g, b) => {
        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
    };

    const [r1, g1, b1] = hexToRgb(color1);
    const [r2, g2, b2] = hexToRgb(color2);

    const r = Math.round(r1 + factor * (r2 - r1));
    const g = Math.round(g1 + factor * (g2 - g1));
    const b = Math.round(b1 + factor * (b2 - b1));

    return rgbToHex(r, g, b);
}

    function generateRedGradient(length) {
        const colors = ["#F53F3F", "#ED6F6F", "#F49B9B", "#E8B8B8"];
        if(length === 1) {
          return [colors[0]]
        }
        const gradient = [];

        for (let i = 0; i < length; i++) {
            const ratio = i / (length - 1);
            if (ratio < 0.33) {
                // Interpolate between #F53F3F and #ED6F6F
                const factor = ratio / 0.33;
                gradient.push(interpolateColor("#F53F3F", "#ED6F6F", factor));
            } else if (ratio < 0.66) {
                // Interpolate between #ED6F6F and #F49B9B
                const factor = (ratio - 0.33) / 0.33;
                gradient.push(interpolateColor("#ED6F6F", "#F49B9B", factor));
            } else {
                // Interpolate between #F49B9B and #E8B8B8
                const factor = (ratio - 0.66) / 0.34;
                gradient.push(interpolateColor("#F49B9B", "#E8B8B8", factor));
            }
        }

        return gradient;
    }

  function getLandfillColors(filteredReport) {
    // Count the number of "Landfill" items
    const landfillCount = filteredReport.filter(ec => ec.label.includes("Landfill")).length;
    // Generate the gradient based on the number of "Landfill" items
    return generateRedGradient(landfillCount);
  }

  function generateBlueGradient(length) {
    const colors = [
        "#43C1F0", // Darker blue
        "#64CFF7", 
        "#82DCFD", 
        "#A8E8FF", 
        "#B9ECFF", 
        "#D5F4FF", 
        "#E0F7FF"  // Lighter blue
    ];
    if(length === 1) {
          return [colors[0]]
        }
    const gradient = [];

    for (let i = 0; i < length; i++) {
        const ratio = i / (length - 1);
        if (ratio < 0.17) {
            const factor = ratio / 0.17;
            gradient.push(interpolateColor("#43C1F0", "#64CFF7", factor));
        } else if (ratio < 0.34) {
            const factor = (ratio - 0.17) / 0.17;
            gradient.push(interpolateColor("#64CFF7", "#82DCFD", factor));
        } else if (ratio < 0.5) {
            const factor = (ratio - 0.34) / 0.16;
            gradient.push(interpolateColor("#82DCFD", "#A8E8FF", factor));
        } else if (ratio < 0.67) {
            const factor = (ratio - 0.5) / 0.17;
            gradient.push(interpolateColor("#A8E8FF", "#B9ECFF", factor));
        } else if (ratio < 0.83) {
            const factor = (ratio - 0.67) / 0.16;
            gradient.push(interpolateColor("#B9ECFF", "#D5F4FF", factor));
        } else {
            const factor = (ratio - 0.83) / 0.17;
            gradient.push(interpolateColor("#D5F4FF", "#E0F7FF", factor));
        }
    }
      return gradient;
  }

  function getBlueColors(filteredReport) {
    // Count the number of non-Landfill items
    const blueCount = filteredReport.filter(ec => !ec.label.includes("Landfill")).length;
    // Generate the gradient based on the number of non-Landfill items
    return generateBlueGradient(blueCount);
  }

  const potentialDataTypes = ["Landfill", "Recycling", "Combustion", "Composting", "Animal Feed", "Anaerobic Digestion"]
  let donutData = null
  let filteredReport = null
  if(wasteReport) {

    function sortByLandfillAndValue(array) {
      return array.sort((a, b) => {
        const aHasLandfill = a.label.includes('Landfill');
        const bHasLandfill = b.label.includes('Landfill');

        // Case 1: Both `a` and `b` have 'Landfill' in their label
        if (aHasLandfill && bHasLandfill) {
          return b[displayType] - a[displayType]; // Sort by amount in decreasing order
        }

        // Case 2: `a` has 'Landfill' and `b` does not
        if (aHasLandfill && !bHasLandfill) {
          return -1; // `a` comes before `b`
        }

        // Case 3: `b` has 'Landfill' and `a` does not
        if (!aHasLandfill && bHasLandfill) {
          return 1; // `b` comes before `a`
        }

        // Case 4: Neither has 'Landfill' in their label
        return b[displayType] - a[displayType]; // Sort by amount in decreasing order
      });
    }
    
    filteredReport = sortByLandfillAndValue(
      wasteReport.emissions_calculations.filter( 
        ec => (ec[displayType] > 0 && potentialDataTypes.includes(ec.label.split(" - ")[0]))
      )
    )

    landfillGradient = getLandfillColors(filteredReport);
    diversionGradient = getBlueColors(filteredReport);

    const labels = filteredReport.map( ec => ec.label.split(" - ")[1])
    const [totalLandfill, totalOthers] = filteredReport.reduce(
      ([landfillSum, othersSum], ec) => {
          if (ec.label.includes("Landfill")) {
            return [landfillSum + ec[displayType], othersSum];
          } else {
            return [landfillSum, othersSum + ec[displayType]];
          }
      },
      [0, 0] 
    );

    let landfillIndex = 0;
    let blueIndex = 0;
        
    const backgroundColor = filteredReport.map(ec => {
        if (ec.label.includes("Landfill")) {
            // Use the next shade from the gradient
            return landfillGradient[landfillIndex++];
        } else {
            return diversionGradient[blueIndex++]; // Default color for non-Landfill items
        }
    });

    donutData = {
      datasets: [
        {
          labels,
          data: filteredReport.map( ec => ec[displayType]),
          borderWidth: 3,
          borderColor: filteredReport.map( (ec, idx) => activeSlices.includes(idx) ? 'black' : '#fff'),
          backgroundColor,
          weight: 1.4
        },
        {
          data: totalOthers ? [totalLandfill, totalOthers] : [totalLandfill],
          labels: totalOthers ? ["Landfill", "Diversion"] : ["Landfill"],
          borderWidth: 3,
          borderColor: '#fff',
          backgroundColor: totalOthers ? ["#CC0000", "#43C1F0"] : ["#CC0000"],
          weight: 0.8,
        },
      ]
    }
  }

  const donutLabels = function(context) {
    var index = context.dataIndex;
    return context.dataset.labels[index] + ': ' + context.dataset.data[index].toFixed(2) + ((displayType === 'total_short_tons') ? " Short Tons" : " kgCO2e");
  }

  const handleActiveSlices = (index) => {
    if(!activeSlices.includes(index)) {
      const newSlices = activeSlices.slice()
      newSlices.push(index)
      setActiveSlices(newSlices)
    } else {
      const newSlices = activeSlices.slice()
      let indexToDelete = activeSlices.indexOf(index) 
      if(indexToDelete > -1) {
        newSlices.splice(indexToDelete, 1)
      }
      setActiveSlices(newSlices)
    }
  }

  const donutClick = (e, activeEls) => {
    if(!activeEls.length) {
      return null
    }
    let datasetIndex = activeEls[0].datasetIndex;
    if(datasetIndex) {
      return null;
    }
    let dataIndex = activeEls[0].index;
    console.log(activeEls[0])
    let dataLabel = e.chart.data.datasets[datasetIndex].labels[dataIndex];
    console.log(e.chart.data, dataLabel)
    handleActiveSlices(dataIndex)
  }

  let blueLegendIndex = 0
  let redLegendIndex = 0

  const donutConfig = {
    type: 'doughnut',
    data: donutData,
    options: {
      onClick: donutClick,
      responsive: true,
      layout: {
        padding: 20
      },
      maintainAspectRatio: false,
      cutout: '40%',
      animation: false,
      plugins: {
        legend: {
          display: false
        },
        tooltip:{
          enabled: !props.disableTooltip,
          callbacks: {
            label: donutLabels
          }
        }
      }
    }
  }

  // Dynamically update the plugin whenever selectedSlice changes
  useEffect(() => {
    const chart = chartRef.current
    if (chart) {
      const meta = chart.getDatasetMeta(0)
      const dataset = chart.data.datasets[0]
      meta.data.forEach((arc, index) => {
          // Increase the outer radius for selected slices
          arc.options.outerRadius = 1000;
      });

      meta.controller.updateElements(meta.data, 0);
      chart.update();
    }
  }, [ activeSlices ])

  const handleClearSelectedSlices = () => setActiveSlices([])

  const WasteLegendRow = ({ec, gradient, legendIndex, idx}) => (
    <div className={classnames("body3 wasteLegendRow", {activeRow: activeSlices.includes(idx)})} onClick={() => handleActiveSlices(idx)} key={idx}>
      <div className="wasteLegendRowLabel">
        <div className="wasteLegendSwatch" style={{backgroundColor: gradient[legendIndex++]}}/>
        {ec.label.split(" - ")[1]}
      </div>
      <div className="wasteLegendRowData">{(ec[displayType]).toFixed(2)}</div>
      <div className="wasteLegendRowData">{(ec[displayType]/(donutData.datasets[1].data[0] + (donutData.datasets[1].data[1] || 0))*100).toFixed(2)}%</div>
    </div>
  )

  return (
    <div className="page wasteManagement"> 
      <Header/>
      <div className="wmHero">
        <div className="heroHeader">
          <h4>
            Overview 
            <Tooltip>
              Waste you generate and where you send it, to illustrate the impact of initiatives and identify opportunities to further reduce waste and divert from the landfill.
            </Tooltip>
          </h4>
          <div className="wasteManagementDisplaySelectContainer">
            <LabeledSelect 
              name="displayType"
              options={[
                {label: 'Weight', value: 'total_short_tons'},
                {label: 'Emissions', value: 'total_kgco2e'},
              ]}
              values={{displayType}}
              onChange={(newVal) => setDisplayType(newVal.target.value)}
            />
          </div>
        </div>
        <div className="heroBanner">
          <div className="wasteSection">
            {
              wasteReport && (
                <div className="wasteMetricContainer">
                  {wasteReport.waste_diversion.map(
                    (metric) => <HomeStatBlock {...metric} key={metric.title}/>
                  )}
                </div>
              )
            }
            {
              wasteReport && (
                <div className="wasteDonut">
                    <WasteDonut chartRef={chartRef} activeSlices={activeSlices} config={donutConfig} />
                </div>
              )
            }
          </div>
          {
            wasteReport && (
              <div className="wasteSection wasteLegend">
                <div className="wasteLegendHeader">
                  <h5>Waste Breakdown</h5>
                  {!!activeSlices.length && <span className="body3" onClick={handleClearSelectedSlices}>Clear selected</span>}
                </div>
                <div className="body4 wasteLegendRow wasteLegendRowHeaders">
                  <div className="wasteLegendRowLabel">Content Type</div>
                  <div className="wasteLegendRowData">{(displayType === "total_short_tons" )? "Short Tons" : "kgCO2e"}</div>
                  <div className="wasteLegendRowData">% of Total</div>
                </div>
                <div className="wasteHeader wasteHeaderLandfill body4">
                  <i className="fa fa-trash" aria-hidden="true"></i>{' '}
                  Landfill: {(donutData.datasets[1].data[0]/(donutData.datasets[1].data[0] + (donutData.datasets[1].data[1] || 0))*100).toFixed(2)}%
                </div>
                {
                  wasteReport && filteredReport.map((ec, idx) => {
                    if(ec.label.includes("Landfill")) {
                      return <WasteLegendRow idx={idx} ec={ec} gradient={landfillGradient} legendIndex={redLegendIndex} /> 
                    }
                  })
                }
                {wasteReport && donutData.datasets[1].data[1] && (
                  <div className="wasteHeader wasteHeaderDiversion body4">
                    <i className="fa fa-recycle" aria-hidden="true"></i>{' '}
                    Diversion: {(donutData.datasets[1].data[1]/(donutData.datasets[1].data[0] + (donutData.datasets[1].data[1] || 0))*100).toFixed(2)}%
                  </div>
                  )
                }
                {
                  wasteReport && donutData.datasets[1].data[1] && filteredReport.map((ec, idx) => {
                    if(!ec.label.includes("Landfill")) {
                      return <WasteLegendRow idx={idx} ec={ec} gradient={diversionGradient} legendIndex={blueLegendIndex} /> 
                    }
                  })
                }
              </div>
            )
          }
        </div>
      </div>
      <br/>
      <div className="accordionBlock">
        {
          wasteReport && (
            <EmissionSection 
              section={wasteReport} 
              currentSectionId={currentSectionId} 
              handleAccordionClick={handleAccordionClick} 
            />
          )
        }
      </div>
    </div>)
}

export default WasteManagement
