import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider'
import Locations from './Locations'
import AdminSusPageDrafts from '../SusPages/Drafts'
import axios from 'axios'
import Questionnaires from '../Questionnaires/Index'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

export default function User() {
  const auth = useAuth()
  const navigate = useNavigate()

  const [ user, setUser ] = useState()
  const [ isLoading, setIsLoading ] = useState(false)

  const [ dupUser, setDupUser ] = useState({email: '', password: '', is_sandbox: true})

  const [ susPage, setSusPage ] = useState({})

  const { id } = useParams()

  useEffect(() => {
    axios.get(`/api/users/${id}`).then((res) => {
      setUser(res.data)
    })
  }, [])

  useEffect(() => {
    if (user?.current_sus_page_id) {
      axios.get('/api/sus_pages/' + user.current_sus_page_id).then((res) => {
        setSusPage(res.data)
      })
    }
  }, [user?.current_sus_page_id])

  const saveUser = () => {
    if (window.confirm("Save user?")) {
      setIsLoading(true)
      const susPagePayload = {
        sus_page: {
          sus_story: susPage.sus_story,
          reduction_strategies: susPage.reduction_strategies
        }
      }
      axios.put(`/api/sus_pages/${user.current_sus_page_id}`, susPagePayload).then((res) => {
        setIsLoading(false)
      })
    }
  }

  const duplicateUser = () => {
    const prompt = `Duplicate ${user.company}?\n\nNew email: ${dupUser.email}\nNew pass: ${dupUser.password}`
    if (window.confirm(prompt)) {
      setIsLoading(true)
      axios.post(`/api/users/${user.id}/duplicate`, {duplicate: dupUser}).then((res) => {
        setIsLoading(false)
        alert('Duplicated successfully')
      })
    }
  }

  const updateDuplicate = (e) => {
    const newDupUser = {...dupUser}
    if (Object.keys(e.target).includes('checked')) {
      newDupUser[e.target.name] = e.target.checked
    } else {
      newDupUser[e.target.name] = e.target.value
    }
    setDupUser(newDupUser)
  }

  const susPageChange = (e) => {
    const newSusPage = {...susPage}
    newSusPage[e.target.name] = e.target.value
    setSusPage(newSusPage)
  }

  const hasNetZeroPlanning = user?.settings?.netZeroSettings

  return(
      <div className="adminPage">

        <header className="pageHeader userProfile">
          <h2>{ user?.company } 
            <input type="button" value="Sus Page"
              onClick={() => window.open(`${process.env.REACT_APP_ROOT_URL}/profile/${btoa(user.current_sus_page_id)}`, '_blank')}
            />
            <input type="button" value="Emissions Planning"
              disabled={!hasNetZeroPlanning}
              onClick={() => window.open(`${process.env.REACT_APP_ROOT_URL}/emissions-planning?userId=${user.id}`, '_blank')}
            />
            <input type="button" value="Locations"
              onClick={() => window.open(`${process.env.REACT_APP_ROOT_URL}/locations?userId=${user.id}`, '_blank')}
            />
            </h2>
        </header>

        <h4>Questionnaires</h4>

        { user && <Questionnaires user={user} /> }

        <h4>Locations</h4>

        { user && <Locations user={user} /> }

        { user && user.monthly_payment_schedule && (
          <>
            <h4>Upcoming Payment Schedule</h4>

            <table>
              <thead>
                { user.monthly_payment_schedule.map((amt) => (<th>{amt.month}/{amt.year}</th>)) }
              </thead>
              <tr>
                { user.monthly_payment_schedule.map((amt) => (<td>${amt.amount}</td>)) }
              </tr>
            </table>
          </>
          )
        }


        <h4>Sustainability Page Drafts</h4>

        { user && <AdminSusPageDrafts user={user} /> }

        <h4>Sustainability Page</h4>

        <table>
          <tbody>
            <tr>
              <td>
                Sustainability Story
              </td>
              <td>
                <textarea name="sus_story" onChange={susPageChange} value={susPage.sus_story || ""}>
                </textarea>
              </td>
            </tr>
            <tr>
              <td>
                Reduction Strategies
              </td>
              <td>
                <textarea name="reduction_strategies" onChange={susPageChange} value={susPage.reduction_strategies || ""}>
                </textarea>
              </td>
            </tr>
          </tbody>
        </table>
        <br/>
        <input type="button" className="positive" value="Save" onClick={saveUser} />


        <h4>Duplicate User</h4>
        <table>
          <tbody>
            <tr>
              <td>
                New Email
              </td>
              <td>
                <input type="email" value={dupUser.email} onChange={updateDuplicate} name="email" />
              </td>
            </tr>
            <tr>
              <td>
                New Password
              </td>
              <td>
                <input type="text" value={dupUser.password} onChange={updateDuplicate} name="password" />
              </td>
            </tr>
            <tr>
              <td>
                Sandbox/Test Account?
              </td>
              <td>
                <input type="checkbox" style={{width: 30, height: 30}} checked={dupUser.is_sandbox} onChange={updateDuplicate} name="is_sandbox" />
              </td>
            </tr>
          </tbody>
        </table>
        <br/>
        <input type="button" className="positive" value="Duplicate" onClick={duplicateUser} />
      </div>
  )
}
