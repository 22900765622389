import React, { useState, useEffect } from 'react'

import LabeledField from '../../Forms/LabeledField'
import LabeledSelect from '../../Forms/LabeledSelect'
import Toggler from '../../Forms/Toggler'
import NavButtons from '../NavButtons'
import recyclingBinImage from '../../../assets/waste_volume_recycling.jpg'
import trashBinImage from '../../../assets/waste_volume_landfill.jpg'
import { ReactComponent as Xsvg } from '../../../assets/X.svg'
import FileUploadComponent from '../../Forms/DropUpload'

import axios from 'axios'

export default function DisposedWaste(props) {

  const PRECISION = 3
  const emptyTotal = {tons: '', cys: ''}
  const [ total, setTotal ] = useState(emptyTotal)
  const [ totalPercent, setTotalPercent ] = useState(0)
  const [ isTons, setIsTons ] = useState(false)

  const emptyWasteContent = {id: '', name: "Select"}
  const [ wasteContents, setWasteContents ] = useState([])

  const emptyDisposedWaste = {id: 'i0', wasteContentId: '', percent: ''}
  const [ disposedWastes, setDisposedWastes ] = useState([emptyDisposedWaste])
  const wasteVolumeImgSrc = props.question.units === 'recycling' ? recyclingBinImage : trashBinImage

  useEffect(() => {
    axios.get('/api/waste_disposal_methods', {params: {name: props.question.units}}).then((res) => {
      setWasteContents([emptyWasteContent, ...res.data.waste_contents])
    })
  }, [])

  useEffect(() => {
    axios.get('/api/disposed_wastes/by_waste_disposal_method', {params: {wasteDisposalMethodName: props.question.units}}).then((res) => {
      console.log(res.data)
      if (res.data.disposed_wastes.length > 0) {
        const first = res.data.disposed_wastes[0]
        if (first.is_by_weight) {
          setIsTons(true)
          setTotal({tons: first.total_tons, cys: ''})
        } else {
          setIsTons(false)
          setTotal({tons: '', cys: first.total_cys})
        }
        const dbDisposedWastes = res.data.disposed_wastes.map((dw) => {
          return ({
            id: `${dw.id}`,
            wasteContentId: `${dw.waste_content_id}`,
            percent: `${dw.percent_of_total}`
          })
        })
        setTotalPercent(currentTotalPercent(dbDisposedWastes))
        setDisposedWastes(dbDisposedWastes)
        console.log(dbDisposedWastes)
      } else {
        setDisposedWastes([emptyDisposedWaste])
      }
    })
  }, [])

  const currentTotalPercent = (disposedWastes) => {
    let newTotal = 0
    for (let i = 0; i < disposedWastes.length; i++) {
      newTotal += coerceInt(disposedWastes[i].percent)
    }
    return newTotal
  }

  const updateTotal = (e) => {
    // if (!/^\d*\.?\d*$/.test(e.target.value)) {
    //   return
    // }
    const newTotal = {}
    if (e.target.value === '') {
      setTotal(emptyTotal)
      return
    }
    const valueFloat = parseFloat(e.target.value)
    if (e.target.name === 'tons') {
      newTotal['tons'] = e.target.value
      newTotal['cys'] = ''

    } else if (e.target.name === 'cys') {
      newTotal['cys'] = e.target.value
      newTotal['tons'] = ''
    }
    setTotal(newTotal)
  }

  function roundIt(value) {
    return Number(value.toFixed(PRECISION))
  }

  const toggleInputType = () => {
    setIsTons(!isTons)
  }


  const isAllowedToAddAnotherDisposedWaste = (currentDisposedWastes) => {
    if (currentDisposedWastes.length >= wasteContents.length - 1) {
      return false
    }
    for (let i = 0; i < currentDisposedWastes.length; i++) {
      if (currentDisposedWastes[i].wasteContentId === '') {
        return false
      }
    }
    return true
  }

  const isWasteContentUsed = (wc, currentDisposedWastes) => {
    for (let i = 0; i < currentDisposedWastes.length; i++) {
      if (parseInt(currentDisposedWastes[i]['wasteContentId']) === parseInt(wc.id)) {
        return true
      }
    }
    return false
  }

  const addBlankDisposedWastes = () => {
    const newDisposedWastes = JSON.parse(JSON.stringify(disposedWastes))
    const blankWaste = {...emptyDisposedWaste, id: 'i'+newDisposedWastes.length}
    setDisposedWastes([...newDisposedWastes, blankWaste])
  }

  const updateDisposedWaste = (id, e) => {
    const newDisposedWastes = JSON.parse(JSON.stringify(disposedWastes))
    const idx = newDisposedWastes.findIndex((dw) => (dw.id === id))
    newDisposedWastes[idx][e.target.name] = e.target.value

    if (e.target.name === "percent") {
      setTotalPercent(currentTotalPercent(newDisposedWastes))
    }
    setDisposedWastes(newDisposedWastes)
  }

  const submit = () => {
    const payload = {
      wasteDisposalMethodName: props.question.units,
      isByWeight: isTons,
      totalCys: total['cys'],
      totalTons: total['tons'],
      disposedWastes: disposedWastes
    }
    console.log('payload', payload)

    return axios.post('/api/disposed_wastes/set_for_waste_disposal_method', payload)
  }

  const nextEnabled = () => {
    if (isTons && coerceInt(total['tons']) === 0) {
      return false
    }
    if (!isTons && coerceInt(total['cys']) === 0) {
      return false
    }
    for (let i = 0; i < disposedWastes.length; i++) {
      if (disposedWastes[i].percent !== '' && disposedWastes[i].wasteContentId === '') {
        return false
      }
    }
    if (totalPercent === 100) {
      return true
    }
  }

  const remainingText = (currentTotal) => {
    if (currentTotal === 100) {
      return `0% remaining`
    }
    if (currentTotal < 100) {
      return `${100 - currentTotal}% remaining`
    }
    if (currentTotal > 100) {
      return `${currentTotal - 100}% over`
    }
  }


  function coerceInt(amt) {
    if (typeof amt === 'string') {
      if (amt.length === 0) {
        return 0
      } else {
        return parseInt(amt)
      }
    } else if (typeof amt === 'number') {
      return parseInt(amt)
    }
  }

  const removeDisposedWaste = (id) => {
    if (window.confirm('Remove waste input?')) {
      console.log('remove it')
    } else {
      return
    }

    const newDisposedWastes = JSON.parse(JSON.stringify(disposedWastes))
    const idx = newDisposedWastes.findIndex((dw) => (dw.id === id))

    if ((`${id}`).indexOf('i') === -1) {
      axios.delete('/api/disposed_wastes/' + id).then((res) => {
        console.log(res)
      })
    }

    newDisposedWastes.splice(idx, 1)

    for (let i = 0; i < newDisposedWastes.length; i++) {
      if ((`${newDisposedWastes[i].id}`).indexOf('i') > -1) {
        newDisposedWastes[i].id = "i" + i
      }
    }

    setTotalPercent(currentTotalPercent(newDisposedWastes))
    setDisposedWastes(newDisposedWastes)
  }

  return (
    <div>
      <div>
        <div className="volumeImage">
          <img src={wasteVolumeImgSrc} />
        </div>
        <h3>{props.question.prompt_text.text}</h3>
        <div className="disposedWasteInputs">
          <div className={`biModalInput ${isTons ? 'second' : 'first'}Active`}>
            <div className="inputContainer">
              <div className="first input">
                <LabeledField
                  label="Cubic yards"
                  name="cys"
                  errors={{}}
                  values={total}
                  onChange={updateTotal}
                />
              </div>
              <div className="second input">
                <LabeledField
                  label="Tons"
                  name="tons"
                  errors={{}}
                  values={total}
                  onChange={updateTotal}
                />
              </div>
            </div>
            <div className="inputSwitcher">
              <Toggler
                checked={isTons}
                onChange={toggleInputType}
              />
            </div>
          </div>
        </div>

        <h3 className="hr">{props.question.prompt_text.body}</h3>

        <div className="contentAllocations">
          <div className="labels">
            <label>Content type</label>
            <label>Amount</label>
          </div>
          {
            disposedWastes.map((disposedWaste, idx) => {
              return (
                <div className="allocation" key={disposedWaste.id}>
                  <div className="contentType">
                  <LabeledSelect
                    name="wasteContentId"                    
                    value={disposedWaste['wasteContentId']}
                    options={ wasteContents.map((wc) => {
                      return (
                        {
                          value: wc.id,
                          label: wc.name,
                          isDisabled: isWasteContentUsed(wc, disposedWastes)
                        }
                      )
                    })}
                    onChange={(e) => {updateDisposedWaste(disposedWaste.id, e)}}
                  />
                  </div>
                  <div className="amount">
                    <LabeledField
                      name="percent"
                      values={disposedWaste}
                      onChange={(e) => {updateDisposedWaste(disposedWaste.id, e)}}
                      placeholder="%"
                    />
                  </div>
                  <div
                    className="removeButton"
                    onClick={() => {removeDisposedWaste(disposedWaste.id)}}
                  >
                    <Xsvg />
                  </div>
                </div>
              )
            })
          }
          <div className="addButtonHolder">
            <input type="button" className="gray addButton" disabled={(!isAllowedToAddAnotherDisposedWaste(disposedWastes))} value="Add another" onClick={addBlankDisposedWastes} />
          </div>

          <div className={`remaining ${totalPercent - 100 === 0 ? '' : 'error'}`}>
            <div className={`micro`}>
              { remainingText(totalPercent) }
            </div>
          </div>
        </div>
      </div>
      <FileUploadComponent folder={props.question.id} />
      <NavButtons
        submit={submit}
        question={props.question}
        goPrev={props.goPrev}
        goNext={props.goNext}
        nextEnabled={nextEnabled}
        noTopMargin
      />
    </div>
  )
}
