import React, {useState, useEffect} from 'react'
import ElectricityGridChart from './ElectricityGridChart'
import ElectricityGridTable from './ElectricityGridTable'

export default function LocationDetail(props) {

    const [width, setWidth] = useState(window.innerWidth)
    const [mobile, setMobile] = useState(width <= 1150)
  
    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth)
      }
      window.addEventListener("resize", handleResize)
      setMobile(window.innerWidth <= 1150)
      return () => window.removeEventListener("resize", handleResize)
    }, [width])

    const kwwhrConverter = (num) => {
      if (num === null) return num
      return Intl.NumberFormat('en-US', {
          notation: "compact",
          maximumFractionDigits: 2
        }).format(num)
    }

    const valueOrDash = (val) => {
        const dash = "-"
        if (val === null) return dash
        return val
    }

    const electricityList = Object.keys(props.location.egrid_energy_composition).map(name => ({name, percent: props.location.egrid_energy_composition[name]}))
    
    return (
        <div className="location-detail">
            <div className='name-address'>
                <span className='body-3'>Location</span>
                <h4>{props.location.name}</h4>
                <span className="body-4">{props.location.street_address}, {props.location.city} {props.location.state}</span>
            </div>
            <div className="stat e-grid body-3">
                Electricity Grid
                {!mobile && <ElectricityGridChart entries={props.location.egrid_energy_composition}/>}
                {mobile && <ElectricityGridTable electricity={electricityList}/>}
            </div>
            <div className="stat-metric-container">
                <div className="stat body-3">
                    kWh/ft²
                    <h3>
                        {valueOrDash(kwwhrConverter(props.location.kwh_per_sqft))}
                    </h3>
                </div>
                <div className="stat body-3">
                    Employees
                    <h3>
                        {valueOrDash(props.location.employee_count)}
                    </h3>
                </div>
                <div className="stat body-3">
                    Avg Commute Distance
                    <h3>
                        {valueOrDash(props.location.avg_commute_distance)}
                    </h3>
                </div>
            </div>
        </div>
    )
}
