import React, { useEffect, createRef } from 'react'
import Chart from 'chart.js/auto'

export default function Donut(props) {

  const chartRef = createRef()
  let chart = null

  const config = () => {
    if (props.config) {
      return props.config
    } else {
      return {
        type: 'doughnut',
        data: props.data,
        options: {
          onClick: props.onClick,
          responsive: true,
          maintainAspectRatio: false,
          cutout: props.cutout || '60%',
          animation: false,
          plugins: {
            legend: {
              display: false
            },
            tooltip:{
              enabled: !props.disableTooltip,
              callbacks: {
                label: props.label
              }
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    chart = new Chart(chartRef.current, config())

    return () => {
      if (chart && chart.destroy) {
        chart.destroy()
      }
    }
  }, [])

  useEffect(() => {

    if (chart && chart.destroy) {
      chart.destroy()
    }

    chart = new Chart(chartRef.current, config())


    return () => {
      if (chart && chart.destroy) {
        chart.destroy()
      }
    }

  }, [props.data])

  return (
    <canvas ref={chartRef} />
  )
}