import React, { useEffect, createRef } from 'react'
import Chart from 'chart.js/auto'
import { cloneDeep } from 'lodash'

export default function WasteDonut(props) {

  const canvasRef = createRef()

  const highlightPlugin = {
    id: 'highlightSlice',
    beforeDatasetDraw(chart) {
      const meta = chart.getDatasetMeta(0);
      const chartWidth = chart.width;
      const chartHeight = chart.height;
      const baseOuterRadius = Math.min(chartWidth, chartHeight) / 2;
      const scalingFactor = 1.05; // Adjust as needed
      const maxOuterRadius = baseOuterRadius * 1.1;

      meta.data.forEach((arc, index) => {
        if (props.activeSlices.includes(index)) {
          // Check if the arc already has an `originalOuterRadius` property
          if (arc.originalOuterRadius === undefined) {
            // Store the original outer radius before enlarging
            arc.originalOuterRadius = arc.outerRadius;
          }

          // Enlarge the arc only if it's not already enlarged
          if (arc.outerRadius === arc.originalOuterRadius) {
            arc.outerRadius = Math.min(arc.outerRadius * scalingFactor, maxOuterRadius);
          }
        } else {
          // Reset to original outer radius for non-active slices if necessary
          if (arc.originalOuterRadius !== undefined) {
            arc.outerRadius = arc.originalOuterRadius;
          }
        }
      });
    }
  }


  const bringSliceToForegroundPlugin = {
    id: 'bringSliceToForeground',
    afterDatasetsDraw(chart) {
      const meta = chart.getDatasetMeta(0)
      props.activeSlices.forEach(index => {
        const arc = meta.data[index]
        if (arc) {
          // Redraw the specific arc to bring it to the foreground
          chart.ctx.save()
          chart.ctx.lineJoin = 'round'
          chart.ctx.lineWidth = arc.options.borderWidth
          chart.ctx.strokeStyle = arc.options.borderColor
          chart.ctx.fillStyle = arc.options.backgroundColor
          arc.draw(chart.ctx)
          chart.ctx.restore()
        }
      })
    }
  }

  const textPlugin = {
  id: 'textPlugin',
  afterDraw(chart) {
    const { ctx, chartArea } = chart;
    const datasetIndex = 1; // Index of the dataset for the inner part of the donut
    const meta = chart.getDatasetMeta(datasetIndex);

    if (!meta.data || meta.data.length === 0) return; // Ensure there are data points

    ctx.save();
    ctx.font = '24px FontAwesome'; // Adjust font size and family as needed
    ctx.fillStyle = '#fff'; // Set text color
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    const textLabels = ['\uf2ed', '\uf1b8']; // Array of letters to display for each segment

    // Iterate over the elements in meta.data and display a letter for each
    meta.data.forEach((element, index) => {
      if (index >= textLabels.length) return; // Stop if there are more segments than letters

      const innerRadius = element.innerRadius;
      const outerRadius = element.outerRadius;
      const centerX = (chartArea.left + chartArea.right) / 2;
      const centerY = (chartArea.top + chartArea.bottom) / 2;
      const textRadius = (innerRadius + outerRadius) / 2; // Position text between inner and outer radius

      const text = textLabels[index]; // Get the letter for the current segment

      // Calculate the midpoint angle of the segment
      const midAngle = (element.startAngle + element.endAngle) / 2;

      // Calculate the position for the letter
      const x = centerX + textRadius * Math.cos(midAngle);
      const y = centerY + textRadius * Math.sin(midAngle);

      // Adjust rotation to keep text upright and rotate counterclockwise by 90 degrees

      // Draw the letter at the calculated position
      ctx.save();
      ctx.translate(x, y);
      ctx.fillText(text, 0, 0);
      ctx.restore();
    });

    ctx.restore();
  }
};

  const config = () => {
    if (props.config) {
      const pluginConfig = cloneDeep(props.config)
      pluginConfig['plugins'] = [ highlightPlugin, bringSliceToForegroundPlugin, textPlugin ]
      return pluginConfig
    } else {
      return {
        type: 'doughnut',
        data: props.data,
        options: {
          onClick: props.onClick,
          responsive: true,
          maintainAspectRatio: false,
          cutout: props.cutout || '60%',
          animation: false,
          plugins: {
            legend: {
              display: false
            },
            tooltip:{
              enabled: !props.disableTooltip,
              callbacks: {
                label: props.label
              }
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d')
    props.chartRef.current = new Chart(ctx, config())

    const rerenderChart = () => {
      props.chartRef.current.update()
    }

    document.fonts.addEventListener('loadingdone', rerenderChart)

    return () => {
      if (props.chartRef.current && props.chartRef.current.destroy) {
        props.chartRef.current.destroy()
        document.fonts.removeEventListener('loadingdone', rerenderChart)
      }
    }
  }, [ props.chartRef, props.data, props.activeSlices ])

  return (
    <canvas ref={canvasRef} />
  )
}
