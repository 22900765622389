import React, { useState, useEffect } from 'react'

import LabeledField from '../../Forms/LabeledField'
import LabeledSelect from '../../Forms/LabeledSelect'
import Toggler from '../../Forms/Toggler'
import NavButtons from '../NavButtons'
import wasteVolumeImgSrc from '../../../assets/waste_volume_compost.jpg'
import { ReactComponent as Xsvg } from '../../../assets/X.svg'
import FileUploadComponent from '../../Forms/DropUpload'

import axios from 'axios'

export default function OrganicDiversion(props) {

  const [ wasteDisposalMethods, setWasteDisposalMethods ] = useState([])
  const [ wasteContents, setWasteContents ] = useState([])
  const [ disposalMethodsByContentId, setDisposalMethodsByContentId ] = useState({})

  const emptyDisposedWaste = {
    id: 'i0',
    wasteContentId: '', 
    wasteDisposalMethodId: '',
    tons: '',
    cys: '',
    isByWeight: false
  }
  const [ disposedWastes, setDisposedWastes ] = useState([emptyDisposedWaste])

  useEffect(() => {
    axios.get('/api/waste_content_disposals/methods_by_content').then((res) => {
      setWasteDisposalMethods(res.data.waste_disposal_methods)
      setWasteContents(res.data.waste_contents)
      setDisposalMethodsByContentId(res.data.methods_by_content_id)
    })
  }, [])

  useEffect(() => {
    axios.get('/api/disposed_wastes/organic_diversions').then((res) => {
      const dbDisposedWastes = res.data.disposed_wastes.map((dw) => {
        return ({
          id: `${dw.id}`,
          wasteContentId: `${dw.waste_content_id}`, 
          wasteDisposalMethodId: `${dw.waste_disposal_method_id}`,
          tons: dw.tons ? `${dw.tons}` : '',
          cys: dw.cys ? `${dw.cys}` : '',
          isByWeight: dw.is_by_weight
        })
      })
      if (dbDisposedWastes.length > 0) {
        setDisposedWastes(dbDisposedWastes)
      }
    })
  }, [])

  const submit = () => {
    const payload = {
      disposedWastes: disposedWastes
    }
    console.log('payload', payload)

    return axios.post('/api/disposed_wastes/set_waste_for_organic_diversion', payload)
  }

  const nextEnabled = () => {
    if (disposedWastes.length === 0) {
      return false
    }
    for (let i = 0; i < disposedWastes.length; i++) {
      if (disposedWastes[i]['wasteContentId'] === '' || disposedWastes[i]['wasteDisposalMethodId'] === '') {
        return false
      }
      if (disposedWastes[i]['isByWeight']) {
        if (disposedWastes[i]['tons'] === '') {
          return false
        }
      } else {
        if (disposedWastes[i]['cys'] === '') {
          return false
        }
      }

    }
    return true
  }

  const wasteContentOptions = () => {
    return [{id: '', name: 'Select'}, ...wasteContents]
  }

  const wasteDisposalOptions = (wasteContentId) => {
    if (wasteContentId === '') {
      return [{id: '', name: "Choose content type"}]
    }
    const okIds = disposalMethodsByContentId[`${wasteContentId}`]
    const okMethods = wasteDisposalMethods.filter((wdm) => (okIds.indexOf(wdm['id']) > -1))
    return [{id: '', name: "Select"}, ...okMethods]
  }

  const updateDisposedWastes = (id, e) => {
    const newDisposedWastes = JSON.parse(JSON.stringify(disposedWastes))
    const idx = newDisposedWastes.findIndex((dw) => (dw.id === id))
    let newVal
    if (e.target.name === 'isByWeight') {
      newVal = e.target.checked
    } else {
      newVal = e.target.value
    }
    newDisposedWastes[idx][e.target.name] = newVal
    if (e.target.name === 'wasteContentId') {
      newDisposedWastes[idx]['wasteDisposalMethodId'] = ''
    }
    setDisposedWastes(newDisposedWastes)
  }

  const addDisposedWaste = () => {
    const newDisposedWastes = JSON.parse(JSON.stringify(disposedWastes))
    const newWithEmpty = [...newDisposedWastes, {...emptyDisposedWaste, id: 'i' + newDisposedWastes.length}]
    console.log(newWithEmpty)
    setDisposedWastes(newWithEmpty)
  }

  const canAddAnother = () => {
    for (let i = 0; i < disposedWastes.length; i++) {
      if (disposedWastes[i]['wasteContentId'] === '' || disposedWastes[i]['wasteDisposalMethodId'] === '') {
        return false
      }
    }
    return true
  }


  const removeDisposedWaste = (id) => {
    if (window.confirm('Remove waste input?')) {
      console.log('remove it')
    } else {
      return
    }

    console.log('id is', id)
    const newDisposedWastes = JSON.parse(JSON.stringify(disposedWastes))
    const idx = newDisposedWastes.findIndex((dw) => (dw.id === id))

    if ((`${id}`).indexOf('i') === -1) {
      axios.delete('/api/disposed_wastes/' + id).then((res) => {
        console.log(res)
      })
    }

    newDisposedWastes.splice(idx, 1)

    for (let i = 0; i < newDisposedWastes.length; i++) {
      if ((`${newDisposedWastes[i].id}`).indexOf('i') > -1) {
        newDisposedWastes[i].id = "i" + i
      }
    }

    setDisposedWastes(newDisposedWastes)
  }

  return (
    <div>
      <div>
        <div className="volumeImage">
          <img src={wasteVolumeImgSrc} />
        </div>
        <h3>{props.question.prompt_text.text}</h3>
        <div className="divertedWastes">
          <div className="divertedWasteInputs">
            {
              disposedWastes.map((dw, idx) => {
                return (
                  <div className="divertedWaste" key={dw.id}>
                    <div className="wasteContent">              
                      <LabeledSelect
                        name='wasteContentId'
                        label="Content Type"
                        value={dw['wasteContentId']}
                        options={ wasteContentOptions().map((wc) => {
                          return (
                            {
                              value: wc.id,
                              label: wc.name
                            }
                          )
                        })}
                        onChange={(e) => { updateDisposedWastes(dw.id, e)}}
                      />
                    </div>
                    <div className="amount">
                      <div className="disposedWasteInputs">
                        <div className={`biModalInput ${dw['isByWeight'] ? 'second' : 'first'}Active`}>
                          <div className="inputContainer">
                            <div className="first input">
                              <LabeledField
                                label="Cubic yards"
                                name="cys"
                                errors={{}}
                                values={dw}
                                onChange={(e) => {updateDisposedWastes(dw.id, e)}}
                              />
                            </div>
                            <div className="second input">
                              <LabeledField
                                label="Tons"
                                name="tons"
                                errors={{}}
                                values={dw}
                                onChange={(e) => {updateDisposedWastes(dw.id, e)}}
                              />
                            </div>
                          </div>
                          <div className="inputSwitcher">
                            <Toggler
                              name="isByWeight"
                              checked={dw['isByWeight']}
                              onChange={(e) => {updateDisposedWastes(dw.id, e)}}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="diversionMethod">
                      <LabeledSelect
                        name='wasteDisposalMethodId'
                        label="Diversion process"
                        value={dw['wasteDisposalMethodId']}
                        options={wasteDisposalOptions(dw['wasteContentId']).map((wdm) => {
                          return (
                            {
                              value: wdm.id,
                              label: wdm.name
                            }
                          )
                        })}
                        onChange={(e) => { updateDisposedWastes(dw.id, e)}}
                      />
                    </div>
                    <div className="removeButton" onClick={() => {removeDisposedWaste(dw.id)}}>
                      <Xsvg />
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="addButtonHolder">
            <input type="button" className="gray addButton" disabled={!canAddAnother()} value="Add another" onClick={addDisposedWaste} />
          </div>
        </div>
      </div>
      <FileUploadComponent folder={props.question.id} />
      <NavButtons
        submit={submit}
        question={props.question}
        goPrev={props.goPrev}
        goNext={props.goNext}
        nextEnabled={nextEnabled}
        noTopMargin
      />
    </div>
  )
}
