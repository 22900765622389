
  export default function metricChangeIsGood(delta, slug) {
    if(!delta) {
      return false
    }
    const goodToGoDownMetrics = [ 'weekly-electricity', 'weekly-natural-gas', 'total-emissions', 'waste-generation' ]
    const goodToGoUpMetrics = [ 'waste-diversion' ]
    if (goodToGoUpMetrics.indexOf(slug) > -1) {
      console.log('is it good')
      return delta > 0
    }
    if (goodToGoDownMetrics.indexOf(slug) > -1) {
      return delta < 0
    }
  }