import React from 'react'
import Accordion from '../Containers/Accordion'


const EmissionSection = ({section, handleAccordionClick, currentSectionId}) => {

  const catHeads = ['Type', 'CO2', 'CH4', 'N2O', 'Other GHGs', 'Total kgCO2e', 'Source']

  if (process.env.REACT_APP_DEBUG) {
    catHeads.unshift('ID')
  }

  const breakdownNumbersForSection = (kg) => {
    return parseFloat(smallOrLargeNumber(kg)).toLocaleString()
  }

  function smallOrLargeNumber(num) {
    if (typeof num === "number") {
      return Math.abs(num) >= 1.0 ? num.toFixed(2) : num.toPrecision(2)
    } else {
      return '...'
    }
  }

  return (
    <div className="emissionCategory" key={section.section_id}>
      <Accordion
        isOpen = {currentSectionId === section.section_id}
        header={(<><h5>{ section.section_name }</h5><h5>{ breakdownNumbersForSection(section.total_kgco2e) } kgCO2e</h5></>)}
        iconPosition="right"
        onAccordionHeadingClick={() => handleAccordionClick(section.section_id)}>
          <div className="tableHolder">
              <table>
                <thead>
                  <tr>
                    { catHeads.map((c) => (<td key={c}>{c}</td>))}
                  </tr>
                </thead>
                <tbody>
                  {
                    section.emissions_calculations && section.emissions_calculations.map((ec) => {
                      if (ec.total_kgco2e === 0) {
                        return null
                      }
                      const co2 = ec.emissions.find((e) => (e.substance_slug === "carbon-dioxide"))
                      const ch4 = ec.emissions.find((e) => (e.substance_slug === "methane"))
                      const no2 = ec.emissions.find((e) => (e.substance_slug === "nitrous-oxide"))
                      const other = ec.emissions.find((e) => (e.substance_slug === "other-greenhouse-gases"))
                      // ['Type', 'CO2', 'CH4', 'N2O', 'Other GHGs', 'Total MTCO2e', 'Source']
                      // 'Power Grid', '149.04 MTCO2e', '2.04 MTCO2e', '9.54 MTCO2e', '2.97 MTCO2e', '159.04 MTCO2e', '$15,421.02'
                      return (
                        <tr key={ec.id}>
                          { process.env.REACT_APP_DEBUG && <td>{ec.id}</td> }
                          <td>
                            { ec.label }
                          </td>
                          <td>
                            { co2 ? breakdownNumbersForSection(co2.amount) : '-' }
                          </td>
                          <td>
                            { ch4 ? breakdownNumbersForSection(ch4.amount * 25.0) : '-'}
                          </td>
                          <td>
                            { no2 ? breakdownNumbersForSection(no2.amount * 298.0) : '-' }
                          </td>
                          <td>
                            { other ? breakdownNumbersForSection(other.amount): '-' }
                          </td>
                          <td>
                            { breakdownNumbersForSection(ec.total_kgco2e) }
                          </td>
                          <td>
                            { ec.source }
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
          </div>
      </Accordion>
    </div>
    )
}

export default EmissionSection